import React from "react";
//https://www.geeksforgeeks.org/es6-date/
import {
  Tooltip,
} from "@mui/material";

const DateFormat = ({ date, time = false }) => {
  const d = new Date(date)
  if(time) {
    return (
      <>{d.toLocaleDateString()} at {d.toLocaleTimeString('en-us',{timeZoneName:'short'})}</>
    )
  }
  return (
    <Tooltip title={d.toLocaleTimeString()}>
      <span>        
        {d.toLocaleDateString()}
      </span>
    </Tooltip>
  )
}

export default DateFormat