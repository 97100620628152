import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Axios from '../utils/axios.js';
import { Money, moneyString } from '../utils/currency.js';
import Date from '../utils/date.js';
import SnackBar from '../components/SnackBar';
import NextLink from "next/link";
import useAuth from "../hooks/useAuth";
import { Helmet } from "react-helmet-async";
import { useRouter } from "next/router";

import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Button as MuiButton,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  CardHeader,
  Table,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TableBody,
  Chip as MuiChip,
  TableCell,
  Dialog,
  TextField,
  Box,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
} from "@mui/material";
import Fab from '@mui/material/Fab';

import { spacing } from "@mui/system";

import Actions from "../components/pages/dashboard/default/Actions";
import DashboardLayout from "../layouts/Dashboard";

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Chip = styled(MuiChip)`
  height: 20px;
  margin-top: -3px;
  font-weight: bold;
  font-size: 75%;
`;

function SimpleTable() {
  const router = useRouter()


  const { user, signOut } = useAuth();

  const [snackBar, setSnackBar] = useState('')

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [query, setQuery] = useState('')

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />

      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            The Feed Dashboard
          </Typography>
          <Typography variant="h6" gutterBottom>
            Welcome back, {user.displayName}.
        </Typography>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={12}>
          <Card title="left">
            <CardContent>
              Please select an option from the left navigation to get started.
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
        </Grid>
      </Grid>

      {snackBar && (
        <SnackBar message={snackBar} />
      )}
    </React.Fragment>
  );
}

SimpleTable.getLayout = function getLayout(page) {

  const router = useRouter()
  
  return (
    <React.Fragment>
    <DashboardLayout>
      {page}
    </DashboardLayout>
    {/* <div style={{position: "absolute", right: 48, bottom: 48 }}>
      <Fab color="primary" aria-label="add" onClick={() => {
        router.push('/finance/vendor-credit/new');
      }}>
        <AddIcon />
      </Fab>
    </div> */}
  </React.Fragment>
  )
};

export default SimpleTable;
