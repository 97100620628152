// import CurrencyFormat from 'react-currency-format'
import currency from 'currency.js'

const Money = ({ cents = 0, includeCents = true }) => {
  // const props = {
  //   prefix: '$',
  //   thousandSeparator: true,
  //   displayType: 'text',
  // }
  // if(includeCents) {
  //   props.decimalScale = 2
  //   props.fixedDecimalScale = true
  // }
  // return (
  //   <CurrencyFormat value={cents / 100} {...props} />
  // )
  return currency(cents/100).format();
}

const moneyString = ({ cents = 0, currency = 'USD' }) => {
  return (cents / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: currency,
  })    
}

export { Money, moneyString };