import axios from "axios";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      const accessToken = window.auth0Client
        ? await window.auth0Client.getTokenSilently({
            audience: "https://dashboard.thefeed.com/auth",
          })
        : null;

      const isAuthenticated = window.auth0Client
        ? await window.auth0Client.isAuthenticated()
        : false;

      if (isAuthenticated) {
        const user = await window.auth0Client.getUser();
        config.headers = {
          ...config.headers,
          user: user.email,
        };
      }

      if (accessToken) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${accessToken}`,
        };
      }
    } catch (error) {
      console.log(error);
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

export default axiosInstance;
