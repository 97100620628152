/*
https://mui.com/material-ui/material-icons/
*/
import { Link, LocalActivity, People, Calculate, Schema, Settings, DirectionsRun, Dns, Warehouse, SupportAgent } from '@mui/icons-material';

const warehouseNavItem = {
  title: "Warehouse",
  href: "/warehouse",
  icon: Warehouse,
  children: [
    {
      title: 'Kitting',
      href: "/warehouse/kitting",
    },
    {
      title: 'Watchtower',
      href: "/warehouse/watchtower",
    },
  ]
}

const customerServiceNavItem = {
  title: "Customer Service",
  href: "/customer-service",
  icon: SupportAgent,
  children: [
    {
      title: 'Order Wizard',
      href: "/customer-service/wizard/start",
    },
    {
      title: 'RightPick®',
      href: "/customer-service/rightpick",
    },
    {
      title: 'Order Ninja',
      href: "/customer-service/ninja",
    },
    {
      title: 'Order Finder',
      href: "/customer-service/lookup",
    },
  ]
}

const pagesSection = [
  {
    href: "/customers",
    icon: People,
    title: "Customers",
  },
  {
    href: "/teams",
    icon: LocalActivity,
    title: "Teams",
  },
  {
    href: "/pim",
    icon: Schema,
    title: "PIM",
    children: [
      {
        href: "/pim/products",
        title: "Products",
      },
      {
        href: "/pim/vendors",
        title: "Vendors",
      },
    ],
  },
  warehouseNavItem,
  customerServiceNavItem,
];

const editorRoleSections = [
  {
    href: "/customers",
    icon: People,
    title: "Customers",
  },
  {
    href: "/teams",
    icon: LocalActivity,
    title: "Teams",
  },
  {
    href: "/redirects",
    icon: Link,
    title: "Redirects",
  },
  {
    href: "/pim",
    icon: Schema,
    title: "PIM",
    children: [
      {
        href: "/pim/products",
        title: "Products",
      },
      {
        href: "/pim/vendors",
        title: "Vendors",
      },
    ],
  },
  warehouseNavItem,
  customerServiceNavItem,
];

const financeRollSections = [
  {
    href: "/customers",
    icon: People,
    title: "Customers",
  },
  {
    href: "/teams",
    icon: LocalActivity,
    title: "Teams",
  },
  {
    href: "/catalog",
    icon: Dns,
    title: "Product Catalog",
    children: [
      {
        href: "/catalog/products",
        title: "Catalog",
      },
      {
        href: "/catalog/vendors",
        title: "Vendors",
      },
      {
        href: "/catalog/brands",
        title: "Brands",
      },
      {
        href: "/catalog/product-groups",
        title: "Product Groups",
      },
    ],
  },
  {
    href: "/finance",
    icon: Calculate,
    title: "Finance",
    children: [
      {
        href: "/finance/vendor-credit",
        title: "Vendor Credit",
      },
      {
        href: "/finance/gross-up",
        title: "Revenue Gross-Up",
      },
      {
        href: "/finance/store-credit",
        title: "Store Credit",
      },
    ],
  },
  {
    href: "/pim",
    icon: Schema,
    title: "PIM",
    children: [
      {
        href: "/pim/products",
        title: "Products",
      },
      {
        href: "/pim/vendors",
        title: "Vendors",
      },
    ],
  },
  warehouseNavItem,
  customerServiceNavItem,
];

const adminRoleSections = [
  {
    href: "/customers",
    icon: People,
    title: "Customers",
  },
  {
    href: "/teams",
    icon: LocalActivity,
    title: "Teams",
  },
  {
    href: "/redirects",
    icon: Link,
    title: "Redirects",
  },
  {
    href: "/athletes",
    icon: DirectionsRun,
    title: "Athletes",
    children: [
      {
        href: "/athletes/applications",
        title: "Applications",
      },
      // {
      //   href: "/athletes/high-tier",
      //   title: "High Tier Athletes",
      // },
      // {
      //   href: "/athletes/low-tier",
      //   title: "Low Tier Athletes",
      // },
    ],
  },
  {
    href: "/pim",
    icon: Schema,
    title: "PIM",
    children: [
      {
        href: "/pim/products",
        title: "Products",
      },
      {
        href: "/pim/attributes",
        title: "Attributes",
      },
      {
        href: "/pim/groups",
        title: "Groups",
      },
      {
        href: "/pim/attribute-categories",
        title: "Attribute Categories",
      },
      {
        href: "/pim/vendors",
        title: "Vendors",
      },
    ],
  },
  {
    href: "/catalog",
    icon: Dns,
    title: "Product Catalog",
    children: [
      {
        href: "/catalog/products",
        title: "Catalog",
      },
      {
        href: "/catalog/vendors",
        title: "Vendors",
      },
      {
        href: "/catalog/brands",
        title: "Brands",
      },
      {
        href: "/catalog/product-groups",
        title: "Product Groups",
      },
    ],
  },
  {
    href: "/finance",
    icon: Calculate,
    title: "Finance",
    children: [
      {
        href: "/finance/vendor-credit",
        title: "Vendor Credit",
      },
      {
        href: "/finance/gross-up",
        title: "Revenue Gross-Up",
      },
      {
        href: "/finance/store-credit",
        title: "Store Credit",
      }
    ],
  },
  {
    href: "/admin",
    icon: Settings,
    title: "Admin",
    children: [
      {
        href: "/admin/users",
        title: "Users",
      },
      {
        href: "/admin/products",
        title: "Product Feed",
      },
      {
        href: "/admin/orders",
        title: "Order Feed",
      },
    ],
  },
  warehouseNavItem,
  customerServiceNavItem,
]

const navItems = {
  "all": [
    {
      pages: pagesSection,
    }
  ],
  "finance": [
    {
      pages: financeRollSections,
    }
  ],
  "admin": [
    {
      pages: adminRoleSections,
    }
  ],
  "editor": [
    {
      pages: editorRoleSections,
    }
  ],
}

export default navItems;
